<!--
 * @Author: chenxing
 * @Date: 2021-04-27 11:38:20
 * @LastEditors: chenxing
 * @LastEditTime: 2022-02-21 15:00:12
-->
<template>
  <div @click.stop @click.self='closeModal' class='material-library img-library'>
    <a-config-provider :locale='locale'>
      <div class='filter-container flexJustifyBetween' @click.self='closeModal' ref='filter'>
        <a-form class='filter-content w100' ref='filterForm' :rules='filterRules' :model='filterCondition'
                :label-col='{ span: 0 }' :wrapper-col='{ span: 24 }'>
          <a-row @click.self='closeModal'>
            <!-- 素材ID、素材名称 -->
            <a-col :span='6'>
              <a-form-item :wrapper-col='{ span: 24 }'>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title' style='width: 120px !important;'>素材名称或id</span>
                  <a-input v-model:value='filterCondition.materialIds' placeholder='请输入素材名称或id' allow-clear
                           @keydown='onInputKeyDown' style='height: 32px' />
                </div>
              </a-form-item>
            </a-col>
            <!-- 素材类型 -->
            <a-col :span='4'>
              <a-form-item>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title'>素材类型</span>
                  <a-select v-model:value='filterCondition.materialChannel' mode='multiple'
                            :options='materialChannelOptions' placeholder='素材类型'
                            :disabled='isDisabled(filterCondition.materialIds || filterCondition.materialIdList)'
                            :show-arrow='true' />
                </div>
              </a-form-item>
            </a-col>
            <!-- 关联产品 -->
            <a-col :span='4'>
              <a-form-item>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title'>关联产品</span>
                  <SelectMultiple class='select-tagmode' v-model:value='filterCondition.projectCodeList'
                                  :select-data='filterCondition.projectCodeList' :show-rectangle='true'
                                  :label-in-value='true' placeholder='关联产品' v-bind='$multipleFilterOptions'
                                  :select-options='projectOptions' select-option-value='projectCode'
                                  select-option-label='projectName'
                                  :disabled='isDisabled(filterCondition.materialIds || filterCondition.materialIdList)' />
                </div>
              </a-form-item>
            </a-col>
            <!-- 使用媒体 -->
            <a-col :span='4'>
              <a-form-item class='mb0'>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title'>使用媒体</span>
                  <a-select placeholder='使用媒体' v-model:value='filterCondition.mediaChannelList' allow-clear
                            :max-tag-count='1' :show-arrow='true' @change='mediaChannelChange' :disabled='tableLoading'>
                    <a-select-option value='TT_2'>巨量引擎2.0</a-select-option>
                    <a-select-option value='TT'>巨量引擎</a-select-option>
                    <a-select-option value='GDT'>腾讯广告</a-select-option>
                    <a-select-option value='GDT_V3'>腾讯广告3.0</a-select-option>
                    <a-select-option value='KS'>磁力引擎</a-select-option>
                    <a-select-option value='QC'>巨量千川</a-select-option>
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <!-- 消耗状态 -->
            <a-col :span='4'>
              <a-form-item>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title'>消耗状态</span>
                  <a-select placeholder='消耗状态' v-model:value='filterCondition.costStatus' allow-clear :show-arrow='true'
                            :filter-option='true' option-filter-prop='label'
                            :disabled='isDisabled(filterCondition.materialIds || filterCondition.materialIdList)'>
                    <a-select-option v-for='option in consumptionStatusOptions' :key='option.value'
                                     :value='option.value'
                                     :label='option.label'>{{ option.label }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <!-- 上传人 -->
            <a-col :span='4'>
              <a-form-item>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title'>上传人</span>
                  <a-select placeholder='上传人' v-model:value='filterCondition.userIdList' allow-clear :show-arrow='true'
                            :filter-option='true' option-filter-prop='label'
                            :disabled='isDisabled(filterCondition.userIdList || filterCondition.userIdList)'>
                    <a-select-option v-for='option in userIdList' :key='option.value' :value='option.value'
                                     :label='option.label'>{{ option.label }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-form-item>
            </a-col>
            <!-- 使用部门 -->
            <a-col :span='4'>
              <a-form-item class='mb0'>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title'>使用部门</span>
                  <SelectMultiple v-model:value='filterCondition.useDeptIds' :select-data='filterCondition.useDeptIds'
                                  :select-options='filterDepIdList' :label-in-value='true' placeholder='使用部门'
                                  v-bind='$multipleFilterOptions' select-option-value='deptId'
                                  select-option-label='name'
                                  :disabled='isDisabled(filterCondition.materialIds || filterCondition.materialIdList)' />
                </div>
              </a-form-item>
            </a-col>
            <!-- 上传部门 -->
            <a-col :span='4' v-show="isMatch=='Y'">
              <a-form-item>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title'>上传部门</span>
                  <a-tree-select class='select-tagmode' tree-checkable :max-tag-count='1'
                                 :show-checked-strategy='SHOW_ALL' v-model:value='filterCondition.deptIdList'
                                 :tree-data='filterDepIdListAll' :replace-fields='replaceFields' placeholder='上传部门'
                                 style='width: 100%' tree-node-filter-prop='title'
                                 :tree-default-expanded-keys='treeDefaultExpandedKeys' allow-clear show-search
                                 :disabled='isDisabled(filterCondition.materialIds || filterCondition.materialIdList)'></a-tree-select>
                </div>
              </a-form-item>
            </a-col>
            <!-- 上传时间 -->
            <a-col :span='6' v-show="isMatch=='Y'">
              <a-form-item :wrapper-col='{ span: 24 }' class='mb0 no-flex'>
                <div style='display: flex; flex-direction: row;align-content: center'>
                  <span class='span-title'>上传时间</span>
                  <a-range-picker class='w100' :placeholder="['上传开始日期', '上传结束日期']" separator='到'
                                  v-model:value='filterCondition.uploadTimeValue' :value-format='dateRangeFormat'
                                  :format='[dateRangeFormat, dateRangeFormat]' :ranges='dateRanges'
                                  :disabled-date='disabledDate' @change='onDateUploadRangeChange'
                                  :disabled='isDisabled(filterCondition.materialIds || filterCondition.materialIdList)'>
                    <template #suffixIcon>
                      <CalendarTwoTone />
                    </template>
                  </a-range-picker>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class='filter-buttons flexBetweenCenter'>
          <a-button class='btn filter-btn' type='primary' @click='onFilterBtnClick' :disabled='tableLoading'>搜索
          </a-button>
          <a-button class='btn cancen-btn' @click='onResetBtnClick' :disabled='tableLoading'>重置</a-button>
        </div>
      </div>
      <div class='table-wrapper no_scroll' @click.self='closeModal' style='padding-bottom: 0'>
        <div class='table-toolbar flexJustifyBetween' @click.self='closeModal'>
          <div>
            <a-space class='toolbar-left' :size='12'>
              <a-button v-hasPermission='`MATERIAL_UPLOAD`' type='primary' @click='onAddMaterialBtnClick'
                        :disabled='tableLoading'>
                <template #icon>
                  <CloudUploadOutlined />
                </template>
                上传图片
              </a-button>
              <a-button v-hasPermission='`MATERIAL_BATCHPUSH`' :disabled='!isSelectMaterialSameProd || tableLoading'
                        @click='batchPush'>
                <template #icon>
                  <SendOutlined />
                </template>
                推送
              </a-button>
              <a-button v-hasPermission='`MATERIAL_BATCHDOWNLOAD`' :disabled='selectedRowKeys.length <= 0'
                        @click='batchDownload'>
                <template #icon>
                  <DownloadOutlined />
                </template>
                下载
              </a-button>
              <a-button v-hasPermission='`MATERIAL_BATCHUPDATE`' :disabled='selectedRowKeys.length <= 0'
                        @click='batchUpdate'>
                <template #icon>
                  <ApiOutlined />
                </template>
                关联产品
              </a-button>
              <a-button v-hasPermission='`MATERIAL_DELETE`' :disabled='selectedRowKeys.length <= 0 || tableLoading'
                        @click='openDelectModal(record)'>
                <template #icon>
                  <DeleteOutlined />
                </template>
                删除
              </a-button>
            </a-space>
          </div>
        </div>

        <!-- 已筛选条件 -->
        <div v-show='getFilterOptionsTag().length'
             style='border: 1px solid #f0f0f0; border-bottom: none; padding: 0 9px 9px 9px'>
          <SelectedConditions :condition-list='getFilterOptionsTag()' @removeTag='removeTag'></SelectedConditions>
        </div>
        <!-- 表格 -->
        <div id='material-library-table'>
          <TableWithStatistic :columns='tableColumns' :data-source='dataSource'
                              :column-width-equal-title='true'
                              :scroll="{ x: scrollWidth, wrapperId: 'material-library-table', interval: 100 }"
                              size='small' class='media-table' ref='videoTable'
                              :row-key="record => record.id + '' + record.mediaChannel + record.ofProjectCode"
                              :loading='tableLoading || tableSetLoading' :pagination='tablePagination'
                              :row-selection='{ getCheckboxProps: getCheckboxProps, selectedRowKeys: selectedRowKeys, onChange: onSelectChange }'
                              @change='onTableChange' :custom-row='rowClick' :custom-header-row='rowHeaderClick'
                              :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
            <template #default='{ record, text, column }'>
              <template v-if="column.dataIndex == 'url'">
                <!-- 图片-->
                <div v-if="record.url !== '-'" class='flexJustifyStart'>
                  <div class='flexAlignBetween'>
                    <div style=' position: relative;display: inline-block;'>
                      <span :style='{backgroundColor: setCostState(record.costStatus)}'
                            style='font-size: 12px; text-align: center;  position: absolute;top: 8px;left: 50%;transform: translateX(-50%); width: 100%;'>
                                               {{ record.costStatus }}
                      </span>
                      <a-popover placement='right'
                                 :mask-closable='false'
                                 :footer='null'
                                 :destroy-on-close='true'
                                 mouseEnterDelay='0.3'>
                        <img class='table-img' :src='getThumbOSSUrl(record)' :alt='record.fileName'
                             @click='onViewDetailsClick(record)' style='cursor: pointer' />
                        <!-- 鼠标移动预览-->
                        <template #content>
                          <img class='table-img' :src='getPreviewUrl(record)' :alt='record.fileName'
                               style='cursor: pointer;width: 240px; height: 427px' />
                        </template>
                      </a-popover>

                    </div>
                  </div>
                </div>
                <div @click='closeModal' v-else>-</div>
              </template>
              <!--  素材ID和名称 -->
              <template v-if="column.dataIndex == 'id'">
                <div>
                  <div>
                    <span style='text-align: left;'>{{ record.fileName }}</span>
                    <!--                    v-hasPermission='`MATERIAL_UPDATE`'-->
                    <a class='copyFileName-a'
                       @click='onTableUpdateClick(record)'>
                      <EditOutlined />
                    </a>
                    <a v-if='showCopyFileName' @click='copyFileName(record.fileName)'
                       class='copyFileName-a'>
                      <CopyOutlined />
                    </a>
                  </div>
                  <div style='color: #AAAAAA;font-size: 13px;'>素材ID：{{ record.id }}</div>
                  <span style='border-width: 1px; border-style: solid;border-color: #F59A23;'
                        v-for=' (item, index) in ttStatusOptions' :key='item.label'
                        :style="{ marginLeft: index === 0 ? '0' : '5px' }">
                     <span style='padding: 10px;color: #F59A23'>
                       {{ item.label }}
                     </span>
                  </span>
                </div>
              </template>
              <template v-if="column.dataIndex == 'operation'">
                <div class='align-l pt10 operation-alink'>
                  <a-space class='flexJustifyStart' :size='14' v-show="record.url !== '-'">
                    <a @click.stop='onViewDetailsClick(record)'>详情</a>
                    <a v-hasPermission='`MATERIAL_BATCHPUSH`' @click='singlePush(record)'>推送</a>
                    <a v-hasPermission='`MATERIAL_DOWNLOAD`' :href='materialDetailsMessage.downloadurl'
                       @click='getDownloadUrl(materialDetailsMessage)'>下载</a>
                    <a-dropdown :trigger="['click']">
                      <a style='width: auto'>
                        <MoreOutlined />
                      </a>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item><a v-hasPermission='`MATERIAL_DOWNLOAD`' :href='record.downloadurl'
                                          @click='copyUrl(record)'>复制链接</a></a-menu-item>
                          <a-menu-item><a v-hasPermission='`MATERIAL_DELETE`'
                                          @click='openDelectModal(record)'>删除</a></a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </a-space>
                </div>
              </template>
              <template v-else-if='text && text.quota'>
                <div class='flexAlignCenter'>
                  <a-tooltip v-if='text.num != 0'>
                    <template #title>
                      <div class='flexJustifyBetween'><span>产品参考：</span>{{ text.quota.productQuota || '0' }}</div>
                      <template v-for='channelQuotaKey in Object.keys(text.quota.productChannelQuota)'
                                :key='channelQuotaKey'>
                        <div class='flexJustifyBetween'>
                          <span>{{ columnMap(channelList, channelQuotaKey) }}：</span>
                          {{ text.quota.productChannelQuota[channelQuotaKey] }}
                        </div>
                      </template>
                    </template>
                    <FallOutlined v-if="text.quota.trend === 'down'"
                                  :style="{ fontSize: '14px', marginRight: '6px', color: text.quota.showICON === 'good' ? '#52c41a' : '#ff4343' }" />
                    <RiseOutlined v-if="text.quota.trend === 'up'"
                                  :style="{ fontSize: '14px', marginRight: '6px', color: text.quota.showICON === 'good' ? '#52c41a' : '#ff4343' }" />
                  </a-tooltip>
                  {{ text.num || '-' }}
                </div>
              </template>
            </template>
          </TableWithStatistic>
        </div>
      </div>

      <!-- 预览素材弹窗 -->
      <a-modal :centered='true' class='picture-modal' v-model:visible='viewActorImagesVisible' :footer='null'>
        <a-carousel arrows :draggable='true' :autoplay='true' class='picture-carousel'>
          <template #prevArrow>
            <div class='custom-slick-arrow' style='left: 10px; z-index: 1'>
              <LeftCircleOutlined />
            </div>
          </template>
          <template #nextArrow>
            <div class='custom-slick-arrow' style='right: 10px'>
              <RightCircleOutlined />
            </div>
          </template>
          <img v-for='item in actorPictureDataList' :key='item' :src='item' class='view-image'
               style='object-fit: contain' />
        </a-carousel>

        <div class='preview-action flexJustifyBetween'>
          <a-space>
            <a :href='previewImgInfo.ossurl' :download='previewImgInfo.fileName' target='_blank'
               @click='getOSSUrl(previewImgInfo)'>下载图片</a>
            <a @click='onViewDetailsClick(previewImgInfo)'>详情</a>
          </a-space>
          <span>{{ previewImgInfo.resolutionLong && previewImgInfo.resolutionHeight ? `${previewImgInfo.resolutionLong} * ${previewImgInfo.resolutionHeight}` : '--'
            }}</span>
        </div>
        <template #closeIcon>
          <CloseOutlined :style="{ color: '#fff', fontSize: '24px' }" />
        </template>
      </a-modal>
      <!-- 素材的详情页面 -->
      <a-drawer width='920' :mask='false' v-model:visible='viewMaterialDrawervisible' class='viewMaterialDetails'
                @close='closeModal' destroy-on-close>
        <template #title>
          {{ viewMaterialTitle }}
          <a-tag class='ml15'
                 :color='columnMapConsumption(consumptionStatusOptions, materialDetailsMessage.costStatus)?.color'>
            {{ columnMapConsumption(consumptionStatusOptions, materialDetailsMessage.costStatus)?.label }}
          </a-tag>
        </template>
        <material-info type='IMAGE' @uploadMaterial='materialEvent' v-if='viewMaterialDrawervisible'
                       :material-details-message='materialDetailsMessage'
                       :filter-condition='filterCondition'></material-info>
      </a-drawer>
      <!-- 批量修改关联产品 -->
      <a-modal v-model:visible='updateMaterialProductVisible' width='428px' title='修改关联产品' @ok='confirmUpdateMaterial'
               @cancel='cancelUpdateMaterial' class='updateMaterialStyle'>
        <a-form-item label='关联产品' :label-col='{ span: 4 }' :wrapper-col='{ span: 18 }'>
          <a-select style='width: 100%' v-model:value='materialModalLargeData.projectCode' placeholder='请选择产品'
                    show-search :filter-option='true' option-filter-prop='label'>
            <a-select-option v-for='option in projectListOptions' :key='option.value' :value='option.value'
                             :label='option.label'>{{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-modal>
      <!-- 自定义列弹窗 -->
      <customize-column :show-check='false' :selected-indicator='selectedIndicator' :fixed-list='fixedList'
                        :column-name='editTempName' title-tips='（勾选媒体渠道的指标，请在对应的渠道下查看）' :menu-list='menuList'
                        :modal-visible='modalVisible' @getDraggableList='getDraggableList'
                        @get-temp-data='getTempData' />
      <!-- 删除提示弹窗 -->
      <delect-modal :delect-modal-visible='delectModalVisible' @onOkClick='onDelectModalOk'
                    @onCancelClick='onDelectModalCancel' />
    </a-config-provider>
  </div>

  <!-- 批量推送 -->
  <batch-push v-model:visible='batchpushShow' :material-list='batchpushMaterial' material-type='IMAGE' />

  <!-- 上传、编辑视频 -->
  <upload-modal v-model:visible='uploadVisible' @editMaterialTitle='editMaterialTitle' :mode='uploadMode'
                v-if='showUploadModal' @after-ok='getMaterialPageListData' ref='uploadModal'
                :modal-data='materialModalData' type='IMAGE' :project-list-options='projectListOptions'
                :upload-person-list='unloadPersonList'></upload-modal>
  <!-- 素材名称修改 -->
  <EditMaterialTitle v-model:visible='editMaterialTitleVisible' :material-name='materialName'
                     @editTitle='getNewMaterialName' />
</template>

<script>
import _ from 'lodash';
import { Empty, message } from 'ant-design-vue';
import { TreeSelect } from 'ant-design-vue';
import moment from 'moment';
import locale from 'ant-design-vue/es/locale/zh_CN';
import {
  DoubleRightOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  CloseOutlined,
  DownOutlined,
  CloudDownloadOutlined,
  CalendarTwoTone,
  CloudUploadOutlined,
  FallOutlined,
  RiseOutlined,
  EditTwoTone,
  SendOutlined,
  DownloadOutlined,
  ApiOutlined,
  DeleteOutlined,
  CopyOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons-vue';
import {
  getMaterialPageData,
  getMaterialDataById,
  deleteMaterialById,
  getUserListData,
  updateLargeProductData,
  exportExcel,
  getAllTemp,
  getChooseTemp,
  getTempName,
  getMaterialNameList,
  getMaterialTotal,
  updateByFileName,
} from '@/api/material/material.js';

import { getProjectListData, getProjectListByCompanyName } from '@/api/base/project.js';
import { getListByDeptTypesData } from '@/api/base/department.js';
import { saveNotRestriction, postUpdateTemp, postDeleteTemp } from '@/api/customize/index.js';
import { getRefreshTime } from '@/api/report';
import { addChineseUnit, isNumber, unique } from '@/utils/utils.js';
import { getUrl, getDownloadUrl } from '@/utils/oss.js';
import { getOpUrl, getOpDownloadUrl } from '@/utils/op_oss.js';
import SelectMultiple from '@/components/selectMultiple/index.vue';
import caliber from '@/components/Customize/caliber.vue';
import CustomizeColumn from '@/components/Customize/customizeColumnModal.vue';
import TempSelect from '@/components/Customize/templateSelect.vue';
import DelectModal from '@/components/DelectModal/index.vue';
import BatchPush from '@/views/material/components/batchPushModal.vue';
import UploadModal from './components/UploadModal.vue';
import MaterialInfo from './components/MaterialInfo.vue';
import EditMaterialTitle from './components/EditMaterialTitle.vue';
import SelectedConditions from '@/views/report/selectedConditions.vue';
import TableWithStatistic from '@/components/TableWithStatistic/index.vue';
import { channelList, datePicker_dateRanges, materialType } from '@/config/globalData.js';
import { getDeptAllTreeList } from '@/api/org/account.js';
import { getIndustryList } from '@/api/customer/product.js';

export default {
  components: {
    // DoubleRightOutlined,
    TableWithStatistic,
    SelectedConditions,
    // caliber,
    // TempSelect,
    CustomizeColumn,
    LeftCircleOutlined,
    RightCircleOutlined,
    CloseOutlined,
    // DownOutlined,
    // CloudDownloadOutlined,

    CalendarTwoTone,
    CloudUploadOutlined,
    DelectModal,
    SelectMultiple,
    UploadModal,
    FallOutlined,
    RiseOutlined,
    MaterialInfo,
    BatchPush,
    EditMaterialTitle,
    // EditTwoTone,
    SendOutlined,
    DownloadOutlined,
    ApiOutlined,
    DeleteOutlined,
    CopyOutlined,
    EditOutlined,
    MoreOutlined,
  },
  data() {
    return {
      isMatch: 'Y',
      foldFilter: false, //是否折叠筛选区
      filterType: 'fileName', //筛选框选择素材名称或者素材id
      editNameId: null, // 编辑素材名称的ID
      editMaterialTitleVisible: false,
      materialName: {},
      SHOW_ALL: TreeSelect.SHOW_ALL,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      IndustryOptions: [], // 所属行业树形数据
      departmentOptions: [], // 部门树形数据
      replaceFields: { children: 'list', title: 'name', key: 'name', value: 'deptId' }, // 替换 treeNode 中 title,value,key,children 字段
      replaceIndustryFields: { title: 'value', key: 'id', value: 'id' }, // 替换 treeNode 中 title,value,key,children 字段
      treeDefaultExpandedKeys: [], //默认展开的节点
      nameDropdown: {
        lazyLoad: true,
        loadOver: false,
        total: 0,
      },
      tableTotal: {}, // 表格总计
      tablePostData: {}, // 表格筛选参数
      selectLoading: false,
      searchNameTime: null, // 计时器
      materialNameOptions: [], // 素材名称列表
      channelList: channelList, // 媒体渠道列表
      batchpushShow: false, // 批量推送弹窗
      batchpushMaterial: [], // 批量推送的素材
      uploadMode: '', //打开上传框的模式（add、edit、update、similar）
      uploadVisible: false,
      showUploadModal: false, //是否渲染上传框
      // 要删除的行数据
      delectRec: {},
      // 打开删除弹窗
      delectModalVisible: false,
      // 打开自定义列表弹窗
      modalVisible: false,
      // 所有配置项
      menuList: [],
      // 所有配置项
      menuListSource: [],
      // 选中的自定义列模板
      activeTemp: null,
      // 编辑列模板
      editTempName: null,
      // 固定指标
      fixedList: [],
      // 已选指标项
      selectedIndicator: [],
      // 模板列表
      tempList: [],
      // 保存的值
      tempUserCustoms: [],
      // 选中的统计口径
      activeConfigType: 'REQUEST_TIME',
      // 素材源数据
      tableSourceData: [],
      // 是否显示具体数字
      amountShowDetail: false,
      locale,
      showCopyFileName: window.isSecureContext,
      // 上次刷新时间
      refreshTime: moment().format('YYYY-MM-DD hh:mm:ss'),
      userInfoGrades: this.$store.state.oauth.userInfo.grades,
      userInfoJobs: this.$store.state.oauth.userInfo.jobs,
      filterRules: { playTimeValue: [{ required: true, message: '请选择投放时间', trigger: 'blur', type: 'array' }] },
      dateRangeFormat: 'YYYY-MM-DD',
      dateRanges: datePicker_dateRanges,
      excelExporting: false,
      projectOptions: [], // 产品列表
      projectListOptions: [],
      materialDetailsKey: '1',
      updateMaterialProductVisible: false,
      unloadPersonList: [],
      viewMaterialTitle: '', //标题
      viewMaterialDrawervisible: false, //查看素材信息抽屉显示
      materialHandleModalVisible: false, //新增编辑会话框显示
      viewActorImagesVisible: false, // 预览图片会话框显示
      actorPictureDataList: [], // 预览图片列表
      materialModalLargeData: {},
      detailPersonInfo: {},
      filterDepIdList: [], //筛选的部门数据
      filterDepIdListAll: [], //筛选的部门数据
      order: 'desc', //正序排列
      updateModalDisable: false, //会话框的非名称输入框禁用
      viewMaterialInformation: {}, //查看素材具体信息
      filterCondition: {}, //筛选条件值
      showDragZone: false, // 拖放区域显示
      clearBlob: false, // 清除上传组件的Blob对象
      allUploaded: true, //是否已完全上传
      materialTableData: [],
      previewImgInfo: {}, // 查看的图片行信息
      materialChannelOptions: [
        { label: '图片', value: 'NBZZ' },
        { label: '外部采购', value: 'WBCG' },
        { label: '客户提供', value: 'KHTG' },
      ],
      tableColumns: [
        {
          title: '',
          dataIndex: 'url',
          key: 'id',
          width: 200,
          slots: {
            customRender: 'url',
          },
          fixed: 'left',
        },
        {
          title: '素材名称和id',
          dataIndex: 'id',
          key: 'url',
          width: 230,
          fixed: 'left',
        },
        {
          title: '操作',
          width: 150,
          dataIndex: 'operation',
        },
        {
          title: '素材类型',
          dataIndex: 'type',
        },
        {
          title: '关联产品',
          dataIndex: 'ofProjectName',

        },
        {
          title: '使用媒体',
          dataIndex: 'mediaChannel',

        },
        {
          title: '总消耗',
          dataIndex: 'costStatus',
        },
        {
          title: '使用部门',
          dataIndex: 'useDeptName',
        },
        {
          title: '上传人',
          dataIndex: 'postName',
        },
        {
          title: '上传时间',
          dataIndex: 'createTime',
        },
      ],
      tableSourceColumns: [
        {
          title: '素材ID',
          dataIndex: 'id',
          key: 'id',
          width: 60,
          fixed: 'left',
        },
        {
          title: '文件预览',
          dataIndex: 'url',
          key: 'url',
          align: 'center',
          sorter: true,
          defaultSortOrder: 'descend',
          sortDirections: ['descend', 'ascend'],
          slots: {
            customRender: 'url',
          },
          width: 430,
          fixed: 'left',
        },
      ],
      selectedRowKeys: [], //选中的行
      selectedRows: [],
      tablePagination: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      tableSorter: { order: 'desc', orderField: ['createTime'] },
      tableLoading: false,
      tableSetLoading: false,
      mediaList: [
        { text: '巨量引擎', value: 'TT' },
        { text: '腾讯广告', value: 'GDT' },
        { text: '腾讯广告3.0', value: 'GDT_V3' },
        { text: '磁力引擎', value: 'KS' },
        { text: '巨量千川', value: 'QC' },
        { text: '巨量引擎2.0', value: 'TT_2' },
      ],
      materialType: {
        QJJ: '情景剧',
        YSJJ: '剪辑',
        CG: '采购',
        KHTG: '客户提供',
        NBZZ: '图片',
      },
      materialModalData: {},
      materialDetailsMessage: {},
      consumeState: [
        { value: '未使用', label: '未使用', color: '#D7D7D7' },
        { value: '零消耗', label: '零消耗', color: '#CAF982' },
        { value: '低消耗', label: '低消耗', color: '#95F204' },
        { value: '有效', label: '有效', color: '#FFFF8O' },
        { value: '优秀', label: '优秀', color: '#FFFFOO' },
        { value: '爆款', label: '爆款', color: '#FACD91' },
        { value: '百万', label: '百万', color: '#F59A23' },
        { value: '五百万', label: '五百万', color: '#EC808D' },
        { value: '千万', label: '千万', color: '#D9001B' },
      ],
      consumptionStatusOptions: [
        {
          label: '未使用',
          value: '未使用',
          color: '#818897',
        },
        {
          label: '有效',
          value: '有效',
          color: '#98704D',
        },
        {
          label: '优秀',
          value: '优秀',
          color: '#12A188',
        },
        {
          label: '爆款',
          value: '爆款',
          color: '#CF1790',
        },
        {
          label: '百万',
          value: '百万',
          color: '#CF1717',
        }, {
          label: '五百万',
          value: '五百万',
          color: 'rgb(127 123 23 / 85%)',
        },
        {
          label: '一千万',
          value: '一千万',
          color: 'rgb(80 61 13 / 85%)',
        },
        {
          label: '低消耗',
          value: '低消耗',
          color: '#CF1717',
        },
        {
          label: '零消耗',
          value: '零消耗',
          color: '#CF1717',
        },
      ],
      rejectTagOptions: [
        { value: 'tt_reject_cnt', label: '巨量拒审' },
        { value: 'gdt_reject_cnt', label: '腾讯拒审' },
        { value: 'ks_reject_cnt', label: '磁力拒审' },
        { value: 'qc_reject_cnt', label: '千川拒审' },
      ],
    };
  },
  provide() {
    return {
      getProjectList: () => this.projectOptions,
      getMaterialDetail: () => this.materialDetailsMessage,
      consumptionStatusOptions: this.consumptionStatusOptions,
      getChannel: () => this.filterCondition.mediaChannelList,
      getMenuListSource: () => this.menuListSource,
      getFilterDepIdList: () => this.filterDepIdList,
    };
  },
  computed: {
    isSelectMaterialSameProd() {
      if (this.selectedRows.length > 0) {
        let prodCode = this.selectedRows[0].ofProjectCode;
        return this.selectedRows.every(row => row.ofProjectCode == prodCode);
      }
      return false;
    },
    uploadFileType() {
      //todo 改为image
      return 'image/*';
    },
    // 表格数据
    dataSource() {
      return this.materialTableData.slice(1) || [];
    },
    // 统计数据
    statistic() {
      return this.materialTableData[0] || {};
    },
    // 表格滚动宽度
    scrollWidth() {
      let width = 0;
      this.tableColumns.forEach(column => {
        const padding = 8 * 2;
        const textWidth = column.width || column.title.length * 12;
        const sortWdith = column.sorter ? 20 : 0;
        width = width + padding + textWidth + sortWdith;
      });
      return width;
    },
  },
  watch: {
    amountShowDetail(newVal) {
      if (!newVal) {
        this.formatAmount(this.materialTableData);
      } else {
        this.formatAmount(this.materialTableData, 'restore');
      }
    },
    filterCondition: {
      handler(newVal) {
        let localStorageValue = { ...newVal };
        localStorageValue.materialIdList = [];
        localStorage.imageFilterCondition = JSON.stringify(localStorageValue);
      },
      deep: true,
    },
    '$store.state.globalEvent.key'() {
      if (this.$store.state.globalEvent.event === 'uploadModalClose') {
        this.viewMaterialDrawervisible = false;
      }
    },
  },
  created() {
    this.getAllTemp().then(() => {
      this.getTempName();
      if (this.$route.query.md5) {
        this.filterCondition = {
          playTimeValue: [moment().subtract(30, 'days'), moment()],
          startMaterialLaunchDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
          endMaterialLaunchDate: moment().format('YYYY-MM-DD'),
          fileName: '',
        };
        localStorage.removeItem('imageFilterCondition');
        this.filterCondition.md5 = this.$route.query.md5;
        this.filterCondition.mediaChannelList = this.$route.query.channel;
        this.filterCondition.playTimeValue = [this.$route.query.sd ? moment(this.$route.query.sd) : moment(), this.$route.query.ed ? moment(this.$route.query.ed) : moment()];
        this.filterCondition.startMaterialLaunchDate = this.$route.query.sd;
        this.filterCondition.endMaterialLaunchDate = this.$route.query.ed;
      } else {
        this.filterCondition.md5 = '';
        delete this.filterCondition.md5;
      }
      this.getMaterialPageListData(null, true);
    });
  },
  mounted() {
    this.getProjectList();
    this.getUserList();
    this.getProjectListFilter();
    this.getDeptListByType();
    this.getMaterialNameList();
    this.getDepartmentTree();
    this.getIndustryList();
    document.addEventListener('click', this.closeModal);
    if (localStorage.imageFilterCondition && localStorage.imageFilterCondition !== 'undefined') {
      this.filterCondition = JSON.parse(localStorage.imageFilterCondition);
      // this.filterCondition.materialChannel = [];
      if (!this.filterCondition.playTimeValue) {
        this.filterCondition.playTimeValue = [moment().subtract(30, 'days'), moment()];
        this.filterCondition.startMaterialLaunchDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        this.filterCondition.endMaterialLaunchDate = moment().format('YYYY-MM-DD');
      }
      this.filterCondition.playTimeValue = [moment(this.filterCondition.playTimeValue[0]), moment(this.filterCondition.playTimeValue[1])];
    } else {
      this.filterCondition = {
        playTimeValue: [moment().subtract(30, 'days'), moment()],
        startMaterialLaunchDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endMaterialLaunchDate: moment().format('YYYY-MM-DD'),
      };
    }
    this.$nextTick(() => {
      this.setTableSticky();
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeModal);
    const drawer = document.querySelector('.viewMaterialDetails');
    if (drawer)
      drawer.removeEventListener('click', e => {
        e.stopPropagation();
      });
  },
  methods: {
    // 单个推送
    singlePush(item) {
      this.batchpushShow = true;
      this.batchpushMaterial = [
        { materialId: item.id, url: item.url, md5: item.md5, fileName: item.fileName },
      ];
    },
    //根据素材总消耗数值设置消耗状态
    setCostState(cost) {
      let state = this.consumeState.find((item) => item.value === cost);
      return state ? state.color : '#D7D7D7'; //
    },
    getCtrText(text, column) {
      if (!text || text.num === '-') {
        return '-';
      } else if (column.dataIndex === 'ctr') {
        return text.num + '%';
      }
      return text.num;
    },
    /**
     * @description: 限制消耗范围
     * @param {*}
     * @return {*}
     */
    onCostChange() {
      let minCostTotal = parseInt(this.filterCondition.minCostTotal);
      let maxCostTotal = parseInt(this.filterCondition.maxCostTotal);
      minCostTotal && (this.filterCondition.minCostTotal = minCostTotal > 0 ? minCostTotal : 0);
      maxCostTotal && (this.filterCondition.maxCostTotal = maxCostTotal > 0 ? maxCostTotal : 0);
      if (minCostTotal && (maxCostTotal || maxCostTotal == 0)) {
        minCostTotal > maxCostTotal && this.$message.error('请输入正确的消耗值范围');
      }
    },
    // 设置分页显隐
    setPagination(antTableBody, pagination) {
      if (!antTableBody) {
        antTableBody = this.$refs.videoTable?.$el?.getElementsByClassName('ant-table-body')?.[0];
      }
      if (!pagination) {
        pagination = this.$refs.videoTable?.$el?.getElementsByClassName('ant-table-pagination')?.[0];
      }
      let show = 'none';
      // 即将触底
      if (antTableBody.scrollHeight - antTableBody.scrollTop <= antTableBody.clientHeight + 44) {
        show = 'block';
      } else {
        show = 'none';
      }
      pagination && (pagination.style.display = show);
    },
    // 设置表格粘滞滚动
    setTableSticky() {
      const antTableBody = this.$refs.videoTable?.$el?.getElementsByClassName('ant-table-body')?.[0];
      let pagination = this.$refs.videoTable?.$el?.getElementsByClassName('ant-table-pagination')?.[0];
      let timer;
      if (antTableBody) {
        antTableBody.addEventListener('scroll', e => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            this.setPagination(antTableBody, pagination);
          }, 10);
          const filterHeight = parseFloat(getComputedStyle(this.$refs.filter).height) + 12; //12是filter的下外边距
          this.$refs.filter.style.marginTop = `-${e.target.scrollTop < filterHeight ? e.target.scrollTop : filterHeight}px`;
        });
      }
    },
    /**
     * @description: 素材名称enter事件
     * @param {*} e
     * @return {*}
     */
    autoComplateEnter(e) {
      e.stopPropagation();
      this.onFilterBtnClick();
    },
    /**
     * @description: 隐藏 tag 时显示的内容
     * @param {*} omittedValues
     * @return {*}
     */
    maxTagPlaceholder(omittedValues) {
      let values = '';
      omittedValues?.forEach(item => {
        values += item.label + ';';
      });
      return (
        <aTooltip title={values}>
          <span>+</span>
          <span style={{ margin: '0 5px' }}>{omittedValues.length}</span>
          <span>...</span>
        </aTooltip>
      );
    },
    /** 打开编辑素材名称弹窗
     * @param {*}
     * @return {*}
     */
    editMaterialTitleOpen(record) {
      this.materialName.oldName = record.fileName;
      this.editNameId = record.id;
      this.editMaterialTitleVisible = true;
    },
    /** 获取新的素材名称
     * @param {*} value
     * @return {*}
     */
    async getNewMaterialName(value) {
      if (value) {
        // let materialType = this.materialName.oldName?.split('.')?.[1] || '';
        let materialType = this.materialName.oldName?.substring(this.materialName.oldName.lastIndexOf('.') + 1) || '';
        if (materialType.length > 4) {
          materialType = 'jpg';
        }
        let fileName = value.indexOf(materialType) !== -1 ? value : value + '.' + materialType;
        let res = await updateByFileName({ id: this.editNameId, fileName: fileName });
        if (res.code == 0) {
          this.$message.success('素材名称修改成功');
          this.editMaterialTitleVisible = false;
          this.materialName.newName = null;
          this.getMaterialPageListData();
        } else {
          this.$message.error('素材名称修改失败');
        }
      }
    },
    // 获取所属行业树形列表
    async getIndustryList() {
      let res = await getIndustryList();
      this.IndustryOptions = res.data;
    },
    /** 编辑名称
     * @param {*}
     * @return {*}
     */
    editMaterialTitle(title) {
      this.viewMaterialTitle = title;
      this.getMaterialPageListData();
    },
    // 获取部门树形数据
    async getDepartmentTree() {
      let res = await getDeptAllTreeList();
      if (res.code === 0 && res.sysDeptEntity) {
        this.departmentOptions = [res.sysDeptEntity];
        this.treeDefaultExpandedKeys = [res.sysDeptEntity.name];
      }
    },
    /** 是否禁用
     * @param {*} value
     * @return {*}
     */
    isDisabled(value) {
      return (Array.isArray(value) ? value.length > 0 : value) ? (this.tableLoading || value ? true : false) : this.tableLoading;
    },
    /** 模糊查询素材名称
     * @param {String} value 关键字
     * @return {*}
     */
    getMaterialNameList(filename, pageData) {
      this.selectLoading = true;
      if (this.searchNameTime) {
        clearTimeout(this.searchNameTime);
      }
      this.searchNameTime = setTimeout(() => {
        const param = {
          size: pageData?.size || 50,
          page: pageData?.page || 1,
          fileName: filename || '',
          materialId: '',
          materialType: 'IMAGE',
        };
        getMaterialNameList(param).then(res => {
          const arr = res?.data.records || [];

          this.nameDropdown.total = res.data.total;
          if (!pageData) {
            this.materialNameOptions = [];
          }
          this.materialNameOptions = this.materialNameOptions.concat(arr).map(item => ({
            key: item.id,
            value: item.fileName,
            label: item.fileName,
          }));
          this.selectLoading = false;
        });
      }, 600);
    },
    toBottom() {
      const len = this.materialNameOptions.length;
      let page = Math.ceil(len / 50);
      if (len == this.nameDropdown.total) {
        this.nameDropdown.loadOver = true;
      } else {
        this.getMaterialNameList('', { page: page + 1, size: 50 });
      }
    },
    /** 遍历素材消耗状态
     * @param {*} columnOptions
     * @param {*} key
     * @return {*}
     */
    columnMapConsumption(columnOptions, key) {
      const returnOpt = columnOptions.filter(item => {
        return item.value == key;
      })[0];
      return returnOpt;
    },
    // 素材详情抛出的事件
    materialEvent(data) {
      this.uploadMode = data.type;
      this.showUploadModal = true;
      this.$nextTick(() => {
        this.uploadVisible = true;
      });
    },
    /** 表格行点击
     * @param {*} record 行数据
     * @param {*} index 行索引
     * @param {*} event 点击元素
     * @return {*}
     */
    rowClick(record, index) {
      return {
        onClick: event => {
          if (event.path?.[0].tagName.indexOf('TD') !== -1) {
            this.closeModal();
          }
        },
      };
    },
    /** 表格表头行点击
     * @param {*} column 列数据
     * @return {*}
     */
    rowHeaderClick(column) {
      return {
        onClick: event => {
          if (event.path?.[0].tagName.indexOf('TH') !== -1) {
            this.closeModal();
          }
        },
      };
    },
    /** 打开删除弹窗
     * @param {Object} record 要删除的行
     * @return {*}
     */
    openDelectModal(record) {
      this.delectRec = record;
      this.delectModalVisible = true;
    },
    /** 删除弹窗确认
     * @param {*}
     * @return {*}
     */
    onDelectModalOk() {
      this.confirmDeleteMaterial(this.delectRec);
      this.delectModalVisible = false;
    },
    /**删除弹窗取消
     * @param {*}
     * @return {*}
     */
    onDelectModalCancel() {
      this.delectModalVisible = false;
    },
    /** 关闭抽屉
     * @param {*}
     * @return {*}
     */
    closeModal() {
      const modal = document.querySelector('.ant-modal');
      if (modal && modal?.style.display != 'none') {
        return;
      }
      const drawer = document.querySelector('.viewMaterialDetails');
      if (drawer)
        drawer.removeEventListener('click', e => {
          e.stopPropagation();
        });
      if (this.viewMaterialDrawervisible) {
        this.viewMaterialDrawervisible = false;
      }
    },
    /** 格式化数组数据
     * @param {Array} arr 需要格式化的数据
     * @return {*}
     */
    formatAmount(arr, type = 'format') {
      const _this = this;
      arr?.forEach((item, index) => {
        Object.keys(item)?.forEach(key => {
          if (type === 'format') {
            if (isNumber(item[key] - 0) && key.indexOf('Code') === -1 && key !== 'id') {
              item[key] = addChineseUnit(item[key] - 0);
            }
          } else if (key !== 'url') {
            let value = (item[key].num || item[key]) + '';
            value = value?.replace('万', '').replace('亿', '') - 0;
            if (value && isNumber(value)) {
              item[key] = _this.tableSourceData[index]?.[key];
            }
          }
        });
      });
    },
    /** 口径下拉变化
     * @param {*}
     * @return {*}
     */
    onTJKJSelect(item) {
      this.activeConfigType = item;
      // this.activeTemp = '默认指标';
      this.getMaterialPageListData(null, true);
    },
    /** 获取素材库所有指标
     * @param {*}
     * @return {*}
     */
    async getAllTemp() {
      let res = await getAllTemp();
      res.list?.forEach(item => {
        item.info?.forEach(childItem => {
          childItem.customConfigId = childItem.id;
          childItem.info?.forEach(info => {
            info.customConfigId = info.id;
          });
        });
      });
      this.menuList = res.list;
      this.menuListSource = _.cloneDeep(res.list);
      this.mediaChannelChange(this.filterCondition.mediaChannelList);
    },
    /** 获取选中的模板已选中指标
     * @param {*}
     * @return {*}
     */
    async getChooseTemp(tempName) {
      if ((this.activeTemp && this.activeTemp !== '默认指标') || (tempName && tempName !== '默认指标')) {
        let res = await getChooseTemp(tempName || this.activeTemp);
        this.selectedIndicator = res.list?.[0]?.info;
        this.setColumn(res.list?.[0]?.info);
      }
    },
    /** 设置自定义列
     * @param {*}
     * @return {*}
     */
    setColumn(arr) {
      this.tableSetLoading = true;
      const _this = this;
      setTimeout(() => {
        // this.tableColumns = _.cloneDeep(this.tableSourceColumns);
        // arr?.forEach(item => {
        //   const itemChannel = item.oneColumnKeys?.split('_')[0];
        //   if ((_this.filterCondition.mediaChannelList && _this.filterCondition.mediaChannelList.indexOf(itemChannel) !== -1) || itemChannel === 'SHARE') {
        //     this.tableColumns?.push({
        //       title: item.columnValue.indexOf('率') !== -1 ? item.columnValue + '(%)' : item.columnValue,
        //       dataIndex: item.columnKey,
        //       sorter: true,
        //       sortDirections: ['descend', 'ascend'],
        //       slots: { customRender: item.columnKey },
        //     });
        //   }
        // });
        this.tableColumns = unique(this.tableColumns);
        this.tableSetLoading = false;
      }, 10);
    },
    /** 获取模板名称
     * @param {*}
     * @return {*}
     */
    getTempName() {
      getTempName().then(res => {
        if (res.code == 0) {
          this.activeTemp = '默认指标';
          this.tempList = res.list;
          this.tempList.unshift({ tempName: '默认指标' });
          if (this.activeTemp && this.activeTemp !== '默认指标') {
            this.getChooseTemp();
          } else {
            const list = this.menuListSource.filter(item => {
              return item.key == 'SHARE_DATA';
            })?.[0]?.info;
            this.setColumn(list);
          }
        }
      });
    },
    /** 关闭弹窗
     * @param {*}
     * @return {*}
     */
    getTempData(tempUserCustoms) {
      if (tempUserCustoms) {
        this.tempUserCustoms = tempUserCustoms;
        let postData = {};
        if (this.operatType === 'add') {
          postData = {
            tempUserCustoms: tempUserCustoms,
          };
          saveNotRestriction(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('新增成功');
              this.modalVisible = false;
              this.selectedIndicator = [];
              this.getTempName();
            } else {
              this.modalVisible = 'loading';
            }
          });
        } else if (this.operatType === 'edit') {
          postData = {
            oldTempName: this.activeTemp,
            tempUserCustoms: tempUserCustoms,
          };
          postUpdateTemp(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('修改成功');
              this.modalVisible = false;
              this.selectedIndicator = [];
              this.getTempName();
            } else {
              this.modalVisible = 'loading';
            }
          });
        }
      } else {
        this.modalVisible = false;
        this.selectedIndicator = [];
      }
      // this.modalVisible = false;
    },
    /** 模板下拉变化
     * @param {*} tempName
     * @return {*}
     */
    getTempNameValue(tempName) {
      this.activeTemp = tempName;
      if (this.activeTemp && this.activeTemp !== '默认指标') {
        this.getChooseTemp();
      } else {
        const list = this.menuListSource.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.setColumn(list);
      }
    },
    /** 媒体渠道筛选
     * @param {*}
     * @return {*}
     */
    async mediaChannelChange(value) {
      // this.getMaterialPageListData();
      this.menuList = this.menuListSource.filter(item => {
        return item.key == 'SHARE_DATA' || item.key.indexOf(value) != -1;
      });

      if (this.activeTemp && this.activeTemp !== '默认指标') await this.getChooseTemp();

      this.onFilterBtnClick();
    },
    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.selectedIndicator = list;
    },
    /** 点击下拉列表编辑
     * @param {String} tempName 模板名称
     * @return {*}
     */
    async editTemp(tempName) {
      this.operatType = 'edit';
      this.editTempName = tempName;
      if (this.editTempName && this.editTempName !== '默认指标') await this.getChooseTemp(tempName);
      this.modalVisible = true;
    },
    /** 点击下拉列表删除
     * @param {String} tempName 模板名称
     * @return {*}
     */
    deleteTemp(tempName) {
      this.operatType = 'delete';
      postDeleteTemp([tempName]).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功');
          this.selectedIndicator = [];
          this.getTempName();
          const list = this.menuListSource.filter(item => {
            return item.key == 'SHARE_DATA';
          })?.[0]?.info;
          this.setColumn(list);
        }
      });
    },
    /** 新增自定义指标
     * @param {*}
     * @return {*}
     */
    addTemp() {
      this.operatType = 'add';
      this.editTempName = null;
      if (this.menuListSource.length > 0) {
        this.selectedIndicator = this.menuListSource.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.modalVisible = true;
      } else {
        this.$message.error('当前口径无可设置指标，请联系后台管理员！');
      }
    },
    /** 获取上次刷新时间
     * @param {*}
     * @return {*}
     */
    getRefreshTime() {
      getRefreshTime('SC').then(res => {
        this.refreshTime = res.list;
      });
    },
    // 获取媒体渠道
    columnMediaType(key) {
      if (key) {
        return this.mediaList.filter(item => {
          return item.value == key;
        })[0].text;
      }
    },
    // 主页表格媒体
    columnMediaArr(key) {
      let mediaArr = [];
      if (key != '-' && key) {
        mediaArr = key.split(',');
        return mediaArr;
      }
    },

    // 获取筛选部门数据
    async getDeptListByType() {
      let res = await getListByDeptTypesData({ deptType: ['OPERATION'] });
      if (res.code === 0) {
        // this.filterDepIdList = res.page?.records;
        this.filterDepIdList = res.data.filter(item => {
          return item.name === '业务二部' || item.name === '业务一部' || item.name === '业务三部' || item.name === '业务五部' || item.name === '业务六部' || item.name === '洽尔' || item.name === '客户自运营';
        });
      }
      let resAll = await getListByDeptTypesData({ deptType: ['OPERATION', 'VIDEO', 'OTHER'] });
      if (resAll.code === 0) {
        // this.filterDepIdListAll = resAll.page.records;
        this.filterDepIdListAll = res.data.filter(item => {
          return item.name === '业务二部' || item.name === '业务一部' || item.name === '业务三部' || item.name === '业务五部' || item.name === '业务六部' || item.name === '洽尔' || item.name === '捕鱼团队';
        });
      }
    },
    onInputKeyDown(e) {
      if (e.key == 'Enter') {
        this.getMaterialPageListData(null, true);
        this.selectedRows = [];
        this.selectedRowKeys = [];
      }
    },
    // 批量推送
    batchPush() {
      this.batchpushShow = true;
      this.batchpushMaterial = this.selectedRows.map(r => {
        return { materialId: r.id, url: r.url, md5: r.md5, fileName: r.fileName };
      });
    },
    // 批量修改素材产品
    batchUpdate() {
      this.closeModal();
      this.updateMaterialProductVisible = true;
    },
    // 批量修改素材确认
    async confirmUpdateMaterial() {
      let ids = [];
      this.selectedRows.forEach(item => {
        if (item.id !== '总计') {
          ids.push(item.id);
        }
      });
      let postParams = {
        ids: ids,
        projectCode: this.materialModalLargeData.projectCode,
      };
      let res = await updateLargeProductData(postParams);
      if (res.code === 0) {
        this.$message.success('批量修改成功');
        this.getMaterialPageListData();
        this.updateMaterialProductVisible = false;
        this.materialModalLargeData = {};
      }
    },
    async batchDownload() {
      //循环下载文件
      let index = 0;
      for (const row of this.selectedRows) {
        if (row.url.startsWith('/')) {
          let downurl = await this.getDownloadUrl(row);
          setTimeout(() => {
            window.location.href = downurl;
          }, 1000 * index);
          index++;
        }
      }
    },

    // 取消批量修改
    cancelUpdateMaterial() {
      this.materialModalLargeData = {};
    },
    // 筛选的产品列表
    async getProjectListFilter() {
      let res = await getProjectListByCompanyName({});
      if (res.code == 0) {
        this.projectOptions = res.page?.list;
      }
    },
    async getUserList() {
      // const deptIdList = this.filterCondition.deptIdList?.map(item => item.value);
      const deptIdList = this.filterCondition.deptIdList ? this.filterCondition.deptIdList : [];
      let res = await getUserListData({ deptIdList });
      if (res.code === 0) {
        //admin
        if (this.$store.state.oauth.userInfo.userId === 1) {
          this.unloadPersonList = res.page?.list;
          return;
        }
        this.unloadPersonList = res.page?.list;
      }
    },
    // 删除某一个素材
    async confirmDeleteMaterial(record) {
      let postParams = [];
      postParams.push(record.id);
      let res = await deleteMaterialById(postParams);
      if (res.code === 0) {
        this.$message.success('删除成功');
        this.getMaterialPageListData();
        this.selectedRows = this.selectedRows.filter(sr => sr.md5 != record.md5);
        this.selectedRowKeys = this.selectedRowKeys.filter(srk => srk.id + srk.mediaChannel != record.id + record.mediaChannel);
      }
    },
    // 导出报表
    async onExportExcelBtnClick() {
      this.excelExporting = true;
      this.tablePostData.costStatus && (this.tablePostData.costStatus = [this.tablePostData.costStatus]);

      let postData = {
        ...this.tablePagination,
        ...this.filterCondition,
        limit: this.tablePagination.limit,
        pageSize: this.tablePagination.pageSize,
        userIdList: this.filterCondition.userIdList?.map(item => item.value) || [],
        useDeptIds: this.filterCondition.useDeptIds?.map(item => item.value) || [],
        // deptIdList: this.filterCondition.deptIdList?.map(item => item.value) || [],
        deptIdList: this.filterCondition.deptIdList ? this.filterCondition.deptIdList : [],
        materialType: 'IMAGE',
        ...this.tableSorter,
        timeline: this.filterCondition.mediaChannelList === 'GDT' || this.filterCondition.mediaChannelList === 'GDT_V3' ? this.activeConfigType : null,
      };
      if (postData.projectCodeList) {
        postData.projectCodeList = [];
        this.filterCondition.projectCodeList.forEach(p => {
          postData.projectCodeList.push(p.value);
        });
      }
      if (this.filterCondition.mediaChannelList) {
        postData.mediaChannelList = Array.isArray(this.filterCondition.mediaChannelList) ? this.filterCondition.mediaChannelList : [this.filterCondition.mediaChannelList];
      } else {
        postData.mediaChannelList = [];
      }

      postData.columns = [];
      this.tableColumns.forEach(item => {
        if (item.dataIndex !== 'id' && item.dataIndex !== 'url') {
          postData.columns.push({ columnKey: item.dataIndex, columnValue: item.title });
        }
      });

      if (postData.materialIds) {
        if (this.filterCondition.materialIds.indexOf(' ') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(' ');
        } else if (this.filterCondition.materialIds.indexOf(',') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(',');
        } else if (this.filterCondition.materialIds.indexOf(';') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(';');
        } else {
          postData.materialIdList = [this.filterCondition.materialIds];
        }
      }
      let res = await exportExcel(postData);
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      let objectUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = objectUrl;
      // link.download = `${this.channelMap[this.activeTabKey]}-${this.dimensionText}`;
      // if (this.filterConditions.startTime) link.download += `-${this.filterConditions.startTime}`;
      // if (this.filterConditions.endTime) link.download += `至${this.filterConditions.endTime}`;
      link.download = 'IMAGE素材报表.xlsx';
      link.click();
      URL.revokeObjectURL(objectUrl);
      this.excelExporting = false;
    },
    // 多选事件
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },

    // 设置table总计多选框不可选
    getCheckboxProps(record) {
      if (record.id === '总计') {
        // 返回的是CheckBox的属性
        return { disabled: true };
      }
    },
    getPreviewUrl(record) {
      if (record.url?.startsWith('http')) {
        return record.url;
      }
      getUrl(record.url).then(url => {
        record.ossurl = url;
      });
      return record.ossurl;
    },
    async getOSSUrl(record) {
      if (record.url?.startsWith('http')) {
        return record.url;
      }
      if (!record.ossurl) {
        if (record.makeCode?.startsWith('op')) {
          record.ossurl = await getOpUrl(record.url);
        } else {
          record.ossurl = await getUrl(record.url);
        }
      }
      return record.ossurl;
    },
    getThumbOSSUrl(record) {
      if (record.url?.startsWith('http')) {
        return record.url;
      }
      if (record.thumb?.startsWith('http')) {
        return record.thumb;
      }
      if (!record.thumbossurl) {
        if (record.makeCode?.startsWith('op')) {
          getOpUrl(record.thumb).then(url => {
            record.thumbossurl = url;
          });
        } else {
          getUrl(record.thumb).then(url => {
            record.thumbossurl = url;
          });
        }
      } else {
        return record.thumbossurl;
      }
    },
    /** 获取文件下载链接
     * @param {*} record
     * @return {*}
     */
    async getDownloadUrl(record) {
      if (!record.downloadurl) {
        if (record.makeCode.startsWith('op')) {
          record.downloadurl = await getOpDownloadUrl(record.url, record.fileName);
        } else {
          record.downloadurl = await getDownloadUrl(record.url, record.fileName);
        }
      }
      return record.downloadurl;
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    columnMap(columnOptions, key) {
      if (key) {
        const returnOpt = columnOptions.filter(item => {
          return item.value == key;
        })[0];
        return returnOpt && returnOpt.label;
      }
    },
    // 开始上传时间结束时间选择
    onDateUploadRangeChange(val, dateString) {
      this.filterCondition.startUpLoadTime = dateString[0];
      this.filterCondition.endUpLoadTime = dateString[1];
    },
    // 开始投放时间选择
    onDatePlayRangeChange(val, dateString) {
      this.filterCondition.startMaterialLaunchDate = dateString[0];
      this.filterCondition.endMaterialLaunchDate = dateString[1];
    },
    //筛选
    onFilterBtnClick() {
      if (this.isMatch === 'N' && this.filterCondition.mediaChannelList === undefined) {
        return message.error('未匹配素材筛选时,必须选择渠道!');
      }
      this.tablePagination.current = 1;
      this.tablePagination.page = '1';
      this.tableTotal = {};
      this.getMaterialPageListData(null, true, null);
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    onFilterBtnClickAndFlush() {
      if (this.isMatch === 'N' && this.filterCondition.mediaChannelList === undefined) {
        return message.error('未匹配素材筛选时,必须选择渠道!');
      }
      this.tablePagination.current = 1;
      this.tablePagination.page = '1';
      this.tableTotal = {};
      this.getMaterialPageListData(null, true, true);
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    //重置筛选
    onResetBtnClick() {
      this.filterCondition = {
        playTimeValue: [moment().subtract(30, 'days'), moment()],
        startMaterialLaunchDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endMaterialLaunchDate: moment().format('YYYY-MM-DD'),
        fileName: '',
      };
      localStorage.imageFilterCondition = JSON.stringify(this.filterCondition);
      this.onFilterBtnClick();
    },
    async getProjectList() {
      let res = await getProjectListData({});
      this.projectListOptions = res.page?.list.map(item => {
        return { label: item.projectName, value: item.projectCode };
      });
    },
    /**获取列表数据
     * @param {*}
     * @return {*}
     */
    async getMaterialPageListData(data, isGetTotal, readFromDB) {
      if (data?.refresh) {
        this.tableLoading = false;
      }
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      if (Array.isArray(this.tableSorter.orderField) && this.tableSorter.orderType) {
        this.tableSorter.orderField = [];
        this.tableSorter.orderField.push(this.tableSorter.orderType == 'url' ? 'createTime' : this.tableSorter.orderType);
      } else if (this.tableSorter.orderType) {
        this.tableSorter.orderField = [];
        this.tableSorter.orderField.push(this.tableSorter.orderType == 'url' ? 'createTime' : this.tableSorter.orderType);
      } else {
        this.tableSorter.orderField = ['createTime'];
        this.tableSorter.order = 'desc';
      }
      if (this.tableSorter.orderField?.length > 1) {
        this.tableSorter.orderField = unique(this.tableSorter.orderField);
      }
      this.tablePostData = {
        ...this.tablePagination,
        ...this.filterCondition,
        // deptIdList: this.filterCondition.deptIdList?.map(item => item.value) || [],
        deptIdList: this.filterCondition.deptIdList ? this.filterCondition.deptIdList : [],
        useDeptIds: this.filterCondition.useDeptIds?.map(item => item.value) || [],
        userIdList: this.filterCondition.userIdList?.map(item => item.value) || [],
        limit: this.tablePagination.limit,
        pageSize: this.tablePagination.pageSize,
        materialType: 'IMAGE',
        timeline: this.filterCondition.mediaChannelList === 'GDT' || this.filterCondition.mediaChannelList === 'GDT_V3' ? this.activeConfigType : null,
        ...this.tableSorter,
        mustReadFromDB: false,

      };
      this.tablePostData.minCostTotal && (this.tablePostData.minCostTotal *= 1000);
      this.tablePostData.maxCostTotal && (this.tablePostData.maxCostTotal *= 1000);
      this.tablePostData.costStatus && (this.tablePostData.costStatus = [this.tablePostData.costStatus]);
      if (this.filterCondition.mediaChannelList) {
        this.tablePostData.mediaChannelList = Array.isArray(this.filterCondition.mediaChannelList) ? this.filterCondition.mediaChannelList : [this.filterCondition.mediaChannelList];
      } else {
        this.tablePostData.mediaChannelList = [];
      }

      if (this.tablePostData.projectCodeList) {
        this.tablePostData.projectCodeList = [];
        this.filterCondition.projectCodeList.forEach(p => {
          this.tablePostData.projectCodeList.push(p.value);
        });
      }
      this.getRefreshTime();
      if (this.tablePostData.materialIds) {
        if (this.filterCondition.materialIds.indexOf(' ') > 0) {
          this.tablePostData.materialIdList = this.filterCondition.materialIds.split(' ');
        } else if (this.filterCondition.materialIds.indexOf(',') > 0) {
          this.tablePostData.materialIdList = this.filterCondition.materialIds.split(',');
        } else if (this.filterCondition.materialIds.indexOf(';') > 0) {
          this.tablePostData.materialIdList = this.filterCondition.materialIds.split(';');
        } else {
          this.tablePostData.materialIdList = [this.filterCondition.materialIds];
        }
      }
      this.tablePostData.materialIdList?.forEach(item => {
        item = item - 0;
      });
      if (this.tablePostData.materialIdList && this.tablePostData.materialIdList.length > 0) {
        this.tablePostData = {
          materialIdList: this.tablePostData.materialIdList,
          ...this.tablePagination,
          limit: this.tablePagination.limit - 1,
          pageSize: this.tablePagination.pageSize - 1,
          materialType: 'IMAGE',
          timeline: this.filterCondition.mediaChannelList === 'GDT' || this.filterCondition.mediaChannelList === 'GDT_V3' ? this.activeConfigType : null,
          ...this.tableSorter,
          mediaChannelList: this.filterCondition.mediaChannelList ? (Array.isArray(this.filterCondition.mediaChannelList) ? this.filterCondition.mediaChannelList : [this.filterCondition.mediaChannelList]) : [],
          startMaterialLaunchDate: this.filterCondition.startMaterialLaunchDate || this.filterCondition.playTimeValue[0]?.format('YYYY-MM-DD'),
          endMaterialLaunchDate: this.filterCondition.endMaterialLaunchDate || this.filterCondition.playTimeValue[1]?.format('YYYY-MM-DD'),
        };
      }

      if (readFromDB) {
        this.tablePostData.mustReadFromDB = readFromDB;
      }
      this.tablePostData.isMatch = this.isMatch;
      let res = await getMaterialPageData(this.tablePostData);
      if (res.code === 0) {
        if (isGetTotal) {
          try {
            this.tableTotal = await getMaterialTotal(this.tablePostData);
            this.tableTotal.map.id = '总计';
            res.page.list?.unshift(_.cloneDeep(this.tableTotal.map));
          } catch (error) {
            console.log(error);
          }
        } else {
          res.page.list?.unshift(_.cloneDeep(this.tableTotal.map));
        }
        this.materialTableData = res.page.list;
        this.tableSourceData = _.cloneDeep(this.materialTableData);
        if (!this.amountShowDetail) {
          this.formatAmount(this.materialTableData);
        }
        this.tablePagination.current = res.page?.currPage;
        this.tablePagination.pageSize = res.page?.pageSize;
        this.tablePagination.total = res.page?.totalCount;
      }
      this.$nextTick(() => {
        this.setPagination();
      });
      this.tableLoading = false;
    },
    /** 获取表格总计
     * @param {*}
     * @return {*}
     */ async getMaterialTotal() {
      this.tableTotal = await getMaterialTotal(this.tablePostData);
      this.tableTotal.map.id = '总计';
      this.materialTableData?.unshift(_.cloneDeep(this.tableTotal.map));
      this.tableSourceData = _.cloneDeep(this.materialTableData);
      if (!this.amountShowDetail) {
        this.formatAmount(this.materialTableData);
      }
      this.tableLoading = false;
    },
    //表格分页
    onTableChange(pagination, filters, sorter) {
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = pagination.pageSize.toString();
      this.tablePagination.pageSize = pagination.pageSize;

      this.tableSorter.orderType = sorter.field;
      this.tableSorter.order = sorter.order == 'ascend' ? 'asc' : 'desc';

      this.selectedRows = [];
      this.selectedRowKeys = [];

      this.getMaterialPageListData();
    },
    // 根据Id获取脚本
    async getMaterialView(id) {
      let res = await getMaterialDataById(id);
    },
    //添加素材
    onAddMaterialBtnClick() {
      this.materialModalData = {
        materialType: 'IMAGE',
        materialList: [],
      };
      this.closeModal();
      this.showUploadModal = true;
      this.$nextTick(() => {
        this.uploadVisible = true;
      });
      this.updateModalDisable = false;
    },
    //编辑素材
    onTableUpdateClick(record) {
      this.uploadMode = 'edit';
      this.materialModalData = {
        ...record,
      };
      this.closeModal();
      this.showUploadModal = true;
      this.$nextTick(() => {
        this.uploadVisible = true;
      });
      this.updateModalDisable = true;
    },
    // 查看素材的详情
    onViewDetailsClick(record) {
      this.clearBlob = false;
      if (!record.ossurl && this.isMatch === 'Y') {
        this.getOSSUrl(record);
      }
      this.viewMaterialDrawervisible = true;
      this.viewMaterialTitle = `${record.fileName} - ${record.id} - ${record.postName}`;
      this.materialDetailsMessage = record;
      if (this.isMatch === 'N') this.materialDetailsMessage.ossurl = record.url;
      this.materialModalData = record;
      this.$nextTick(() => {
        const drawer = document.querySelector('.viewMaterialDetails');
        drawer.addEventListener('click', e => {
          e.stopPropagation();
        });
      });
    },
    /** 预览图片
     * @param {*} fileName 图片名称
     * @return {*}
     */
    async preview(record) {
      if (!record.ossurl) {
        await this.getOSSUrl(record);
      }
      let fileName = record.ossurl;
      this.previewImgInfo = record;
      this.actorPictureDataList = [];
      this.actorPictureDataList.push(fileName);
      let image = new Image();
      image.src = fileName;
      this.viewActorImagesVisible = true;
    },
    /** 复制文件名
     * @param fileName 要复制的内容
     */
    copyFileName(fileName) {
      navigator.clipboard.writeText(fileName);
      this.$message.success('已复制');
    },

    /** 点击关联账户数量跳转关联详细数据
     * @param {Object}} record 点击的行数据
     * @return {*}
     */
    viewMediaDetails(record) {
      this.onViewDetailsClick(record);
      this.materialDetailsKey = '3';
    },

    // 生成已筛选条件tag数据
    getFilterOptionsTag() {
      const filterValueDict = {
        materialIds: '素材id',
        fileName: '素材名称',
        materialChannel: '素材类型',
        projectCodeList: '产品',
        deptIdList: '部门',
        userIdList: '上传人员',
        shootingName: '摄像',
        producerName: '制片',
        screenwriterName: '编导',
        direction: '素材方向',
        uploadTimeValue: '上传时间',
        useDeptIds: '使用部门',
        // playTimeValue: '投放时间',
        mediaChannelList: '媒体渠道',
        costStatus: '投放状态',
        rejectTag: '拒审状态',
      };
      const tagList = [];
      const keys = Object.keys(filterValueDict);
      keys.forEach(key => {
        if (this.filterCondition[key]) {
          let label = filterValueDict[key];
          let value = [];
          if (key == 'rejectTag') {
            value = [this.columnMap(this.rejectTagOptions, this.filterCondition[key])];
          } else if (key == 'uploadTimeValue') {
            let str = this.filterCondition.uploadTimeValue?.join('——');
            str && value.push(str);
          } else if (key == 'materialChannel') {
            value = this.filterCondition.materialChannel.map(item => materialType[item]) || [];
          } else if (Array.isArray(this.filterCondition[key]) && this.filterCondition[key].length) {
            this.filterCondition[key].forEach(item => {
              if (typeof item == 'object') {
                value.push(item.label);
              } else {
                value.push(item);
              }
            });
          } else if (!Array.isArray(this.filterCondition[key])) {
            let val = this.filterCondition[key];
            if (key == 'direction') {
              val = { HORIZONTAL: '横向', VERTICAL: '竖向' }[val];
            } else if (key == 'mediaChannelList') {
              val = this.mediaList.find(item => item.value == val)?.text;
            }
            value.push(val);
          }
          value.length && tagList.push({ label, value, key });
        }
      });

      let label = '消耗区间';
      let value = '';
      if (this.filterCondition.maxCostTotal && this.filterCondition.minCostTotal) {
        value = [`${this.filterCondition.minCostTotal}元 ≤ 消耗 ≤ ${this.filterCondition.maxCostTotal}元`];
      } else if (this.filterCondition.minCostTotal) {
        value = [`${this.filterCondition.minCostTotal}元 ≤ 消耗`];
      } else if (this.filterCondition.maxCostTotal) {
        value = [`消耗 ≤ ${this.filterCondition.maxCostTotal}元`];
      }
      value.length && tagList.push({ label, value, key: 'costRange' });
      return tagList;
    },
    removeTag({ tag }) {
      if (tag.key == 'costRange') {
        this.filterCondition.minCostTotal = undefined;
        this.filterCondition.maxCostTotal = undefined;
      } else if (Array.isArray(this.filterCondition[tag.key])) {
        this.filterCondition[tag.key] = [];
      } else {
        this.filterCondition[tag.key] = null;
      }
      this.getMaterialPageListData(null, true);
      if (!this.filterCondition.mediaChannelList) {
        this.mediaChannelChange();
      }
    },
  },
};
</script>
<style lang='less' scoped>
.filter-container {

  .span-title {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 0 11px;
    height: 32px;
    line-height: 28px;
    width: 100px;
    text-align: center;
  }

  :deep(.filter-content) {
    padding-right: 35px;
    margin-left: 0;

    .ant-form-item-label {
      line-height: 33px !important;
    }

    .ant-form-item-control-wrapper.ant-col-18,
    .ant-form-item-control-wrapper.ant-col-19 {
      flex-grow: 1;
      max-width: none;
    }

    .ant-calendar-picker-input {
      height: 32px !important;
    }
  }

  .filter-buttons {
    position: relative;
    font-size: 12px;

    .filter-fold-icon {
      position: absolute;
      top: 4px;
      left: -46px;
      height: 24px;
      border-radius: 2px;
      text-align: center;
      line-height: 24px;
      color: #197afb;
      cursor: pointer;

      :deep(svg) {
        transform: rotateZ(-90deg);
      }

      &.folded :deep(svg) {
        transform: rotateZ(90deg);
      }
    }
  }
}

.picture-modal {
  min-height: 100px;
  padding: 0;
  background: #fff;

  .picture-carousel {
    width: 100%;
  }

  .ant-modal-body {
    padding: 0;
  }

  .preview-action {
    height: 3em;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-right: 2em;
    padding-left: 2em;
  }

  .anticon.anticon-close {
    background: #8a8383;
    border-radius: 50%;
    padding: 2px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    &:hover {
      background: #b9b0b0;
      color: #191616 !important;
    }
  }
}

.updateList {
  max-height: 6rem;
  overflow-y: scroll;

  .ant-list-item {
    padding: 0.1rem 0.2rem;
  }
}

.updateMaterialStyle {
  .ant-modal-content {
    min-height: 7.5rem;

    .ant-modal-body {
      padding: 1.41rem 1.41rem 0.75rem 1.41rem;

      .ant-select {
        width: 6.75rem;
      }
    }
  }
}
</style>
<style lang='less'>
.operation-alink a {
  color: #555555;
}

.operation-alink a:hover {
  color: #2f70f4;
}

.material-library {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .table-wrapper {
    height: 200px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    #material-library-table {
      height: 200px;
      flex-grow: 1;
    }

    .media-table {
      .material_title_edit {
        display: none;
        cursor: pointer;
      }

      .material_title:hover .material_title_edit {
        display: inline-block;
      }

      .video-player {
        position: absolute;
        background: #fff;
      }

      &.table-with-statistic {
        .column48 {
          height: 36px;

          .ant-table-header-column {
            height: 20px;

            & > div {
              height: 100%;
              display: flex;
              align-items: center;
            }
          }
        }

        tr {
          th,
          td {
            padding: 8px;
          }
        }

        // 斑马纹
        .table-striped {
          background-color: #fafafa;
        }

        .ant-table-body,
        .ant-table-body-inner {
          padding-bottom: 44px;
        }
      }

      .ant-table-header {
        tr:nth-child(2) > * {
          color: #197afb;
          font-weight: normal;
        }
      }

      // 分页模块
      .ant-table-pagination {
        display: none;
        margin: 10px 0 !important;
        transform: translate(-10px, -50px);
      }
    }
  }
}

.no-flex {
  .ant-form-item-label {
    flex: none;
  }
}

.picture-modal {
  width: 60% !important;

  .slick-slide.slick-current {
    max-height: 24rem;
    // overflow-y: auto;
  }

  .ant-modal-body {
    padding: 0;
  }

  .view-image {
    max-height: 24rem;
    width: 60vw;
    object-fit: contain !important;
  }
}
</style>